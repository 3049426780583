<template>
  <b-col class="d-flex justify-content-end" cols="2">
      <div>
        <b-button @click="init" variant="primary" v-b-modal.modal-lg>
          {{ !immediacybutton ? "一键诊断" : "诊断中" }}
        </b-button>
      </div>

    <!-- :ok-title="showResultsButton ? '查看结果' : '等待中...'" -->
    <b-modal
      id="modal-lg"
      ok-only
      centered
      size="lg"
      title="一键诊断"
      @ok="ToAgentResult"
      @hide="handleCloseModal"
      hide-footer
    >
      <!--      <b-card>-->
      <!--        <b-card-text class="text-center">-->
      <div class="full-width full-height">
        <div
          style="height: 505px"
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <div
            v-if="step === 0"
            style="height: 100%"
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <p class="mb-5 mt-5 text-center">一键诊断文字介绍</p>

            <!-- <b-row>
              <b-col cols="6"> -->
            <b-button
              class="btn-lg"
              style="height: 80px; width: 300px; margin-top: 30px"
              variant="outline-primary"
              @click="ToDiagnosis"
            >
              <b-icon-hand-index-fill class="mr-50"></b-icon-hand-index-fill>
              一键诊断
            </b-button>
            <!-- </b-col>
              <b-col cols="6"> -->
            <b-button
              class="btn-lg"
              style="height: 80px; width: 300px; margin-top: 30px"
              variant="outline-primary"
              @click="ToAgentResult"
            >
              <b-icon-hand-index-fill class="mr-50"></b-icon-hand-index-fill>
              查看上次诊断结果
            </b-button>
            <!-- </b-col>
            </b-row> -->
          </div>
          <b-row style="width: 100%" class="full-height" v-if="step == 1">
            <!-- offset="2" -->
            <b-col
              cols="5"
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <b-avatar variant="light-primary" size="66">
                <b-icon-search scale="2.5"></b-icon-search>
              </b-avatar>
              <b-progress
                :value="(responseLength / 11) * 100"
                max="100"
                show-progress
                animated
                class="mt-2 w-100"
              ></b-progress>

              <b-button
                v-if="showResultsButton"
                variant="primary"
                class="mt-2"
                @click="ToAgentResult"
              >
                查看结果
              </b-button>
              <p v-else class="mt-2">奋力诊断中...</p>

              <!-- <b-button variant="primary">
                <b-icon-check-circle-fill
                  class="mr-50"
                ></b-icon-check-circle-fill>
                查看诊断结果</b-button
              > -->
            </b-col>
            <!-- border-variant="primary" -->
            <b-col cols="7 " class="pr-0">
              <!-- <b-card
                class="mb-0 h-100 mr-0"
              > -->
              <!-- 代码可视化 -->
              <div id="terminal" style="width: 100%; background: black"></div>
              <!-- </b-card> -->
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- <b-row>
        <b-col>
          <b-button
            v-if="showResultsButton"
            variant="primary"
            @click="ToAgentResult"
          >
            查看结果
          </b-button>
        </b-col>
      </b-row> -->

      <!--        </b-card-text>-->
      <!--      </b-card>-->
    </b-modal>
  </b-col>
</template>
<script>
import router from "@/router";
import "xterm/css/xterm.css";
import {
  onMounted,
  ref,
  reactive,
  computed,
  nextTick,
  onBeforeUnmount,
} from "@vue/composition-api";
import { Terminal } from "xterm";
import { routerParams } from "@/libs/utils/routerParams";
import store from "@/store";
import { showToast } from "@/libs/utils/showToast";

export default {
  name: "ComponentDiagnosisDialog",
  setup() {
    const step = ref(0);

    const event = reactive({
      project_id: computed(() => {
        return routerParams("project_id");
      }),
    });
    let checkStatuTimer; // 定时器引用
    let shouldContinueGetLogs = true; // 标志用于控制是否继续执行 getLogs()

    const ToAgentResult = () => {
      router.push({ path: "/AgentResult" });
    };
    const showProgress = ref(false);
    const showResultsButton = ref(false); // 控制按钮显示状态
    const responseLength = ref(0); // 响应数据的长度
    const showButton = ref(false);
    const immediacybutton = ref(false);

    const term = ref(null);

    const ToDiagnosis = () => {
      step.value++;
      getImmediacyExecute();
      console.log("一键诊断", step.value);
      if (step.value == 1) {
        immediacybutton.value = true;
        // setTimeout(() => {
        //   getCheckStatu();
        // }, 1000);

        setTimeout(() => {
          initTerminal();
        }, 0);
      }
    };

    const init = () => {
      // step.value = 0;
      // getCheckStatu().then((response) => {
      //   console.log("你好")
      //   console.log("点击按钮请求",response.status)
      //   if (response.status == "400") {
      //     step.value = 1;
      //   } else {
      //     step.value = 0;
      //   }
      //   console.log("打开弹窗的时候到第几步", step.value);
      // });
      if (step.value == 1) {
        immediacybutton.value = true;
        getCheckStatu();
        setTimeout(() => {
          initTerminal();
        }, 0);
      }
    };

    const initTerminal = () => {
      // console.log("终端显示===");
      term.value = new Terminal({
        convertEol: true, //启用时，光标将设置为下一行的开头
        disableStdin: true, //是否应禁用输入
        cursorBlink: true, //光标闪烁
        theme: {
          foreground: "#ECECEC", //字体
          background: "#000000", //背景色
          cursor: "help", //设置光标
          lineHeight: 20,
        },
        cols: 48,
        rows: 28,
      });
      // term.value = new Terminal({
      //   enableInput: false, // 禁用输入
      // });
      term.value.open(document.getElementById("terminal"));
      // term.value.prompt = (_) => {
      //   term.write("\r\n\x1b[33m$\x1b[0m ");
      // };
      // term.value.prompt()
      // term.write("Hello from \x1B[1;3;31mxterm.js\x1B[0m $ ");
      // term.write("你好");
    };

    // 一键诊断请求
    const getImmediacyExecute = () => {
      console.log("开始请求");
      const project_id = event.project_id;
      store
        .dispatch("projects/fetchImmediacyExecute", project_id)
        .then((response) => {
          console.log("一键诊断", response, response.status);

          getCheckStatu();
        })
        .catch((error) => {
          console.log("请求失败");
          showToast(error);
        });
    };

    // 检查是否在诊断中
    const getCheckStatu = () => {
      console.log("开始请求getCheckStatu");
      const project_id = event.project_id;
      store
        .dispatch("projects/checkStatu", project_id)
        .then((response) => {
          console.log("检查是否在诊断中", response);
        })
        .catch((error) => {
          console.log("请求失败getCheckStatu", error);
          const status = error.response ? error.response.status : "未知状态";
          console.log("错误状态码：", status);
          if (status == "400") {
            checkStatuTimer = setInterval(() => {
              getLogs();
            }, 5000);
          }
          // showToast(error);
        });
    };

    // const getCheckStatu = () => {
    //   return new Promise((resolve, reject) => {
    //     console.log("开始请求getCheckStatu");
    //     const project_id = event.project_id;
    //     store
    //       .dispatch("projects/checkStatu", project_id)
    //       .then((response) => {
    //         console.log("检查是否在诊断中", response);
    //         resolve(response); // 使用响应结果来 resolve 这个 Promise
    //       })
    //       .catch((error) => {
    //         console.log("请求失败getCheckStatu", error);
    //         const status = error.response ? error.response.status : "未知状态";
    //         console.log("错误状态码：", status);
    //         if (status == "400") {
    //           checkStatuTimer = setInterval(() => {
    //             if (shouldContinueGetLogs) {
    //               getLogs();
    //             }
    //             // getLogs();
    //           }, 5000);
    //         }
    //         showToast(error);
    //         reject(error); // 使用错误信息来 reject 这个 Promise
    //       });
    //   });
    // };

    // 获取诊断日志
    const getLogs = () => {
      console.log("开始请求getLogs");
      const project_id = event.project_id;
      store
        .dispatch("projects/getLogs", project_id)
        .then((response) => {
          console.log("获取诊断日志", response.data);
          term.value.clear(); // 清除内容
          response.data.forEach((log, index) => {
            if (index !== response.data.length - 1) {
              term.value.write(`${log}\n`);
            }
          });
          responseLength.value = response.data.length - 1;
        })
        .catch((error) => {
          console.log("请求失败", error.response.data);
          const status = error.response ? error.response.status : "未知状态";

          if (status == "400") {
            console.log("诊断结束");
            // console.log("获取日志状态码", status);
            term.value.clear(); // 清除内容
            error.response.data.forEach((log, index) => {
              // console.log("为什么没有写上去", log);
              if (index !== error.response.data.length - 1) {
                term.value.write(`${log}\n`);
              }
            });
            responseLength.value = error.response.data.length - 1;

            // 停止继续执行 getLogs()
            shouldContinueGetLogs = false;

            console.log("定时器", checkStatuTimer, responseLength.value);

            // 清除定时器
            clearInterval(checkStatuTimer);
            // 显示查看结果按钮
            showResultsButton.value = true; // 显示按钮
            // step.value = 0

            console.log("定时器", checkStatuTimer);
            // 更新首页的按钮 为 一键诊断
            immediacybutton.value = false;
            console.log("一键诊断的按钮", immediacybutton.value);
          }

          // showToast(error);
        });
    };

    const handleCloseModal = () => {
      responseLength.value = 0
      // 在此处添加模态框关闭时的处理逻辑
      console.log("模态框关闭了");
      // 例如执行某些操作、重置数据等
      if(showResultsButton.value == true){
        step.value = 0
        showResultsButton.value = false
        responseLength.value = 0
      }
    };

    onMounted(() => {
      const project_id = event.project_id;
      store
        .dispatch("projects/checkStatu", project_id)
        .then((response) => {
          console.log("检查是否在诊断中", response);
          step.value = 0;
          showButton.value = true;
        })
        .catch((error) => {
          console.log("请求失败getCheckStatu", error);
          const status = error.response ? error.response.status : "未知状态";
          console.log("错误状态码：", status);
          if (status == "400") {
            step.value = 1;
            immediacybutton.value = true;
          }
          showButton.value = true;
        });
    });

    onBeforeUnmount(() => {
      shouldContinueGetLogs = false;
      // 清除定时器
      clearInterval(checkStatuTimer);
    });

    return {
      ToAgentResult,
      showProgress,
      ToDiagnosis,
      step,
      init,
      showResultsButton, // 返回按钮显示状态
      responseLength,
      showButton,
      immediacybutton,
      handleCloseModal
    };
  },
  mounted() {
    // this.init();
  },
};
</script>
<style scoped>
>>> .modal-body {
  padding: 0 !important;
}

#terminal {
  width: 100%;
  /* overflow-x: auto; */
}
</style>
