import {ref,reactive,computed} from "@vue/composition-api";
import TopMenusJson from './TopMenusJson'
import router from '@/router'
import { routerParams } from "@/libs/utils/routerParams";


const activeIndex = ref(0);
const currentRouteName = ref('')
const group_uuid = ref('')

// 实体监控 68d3d452 报警管理 db04ad2d   kpi分析 d1eccb40

export default () => {

    const event = reactive({
        project_id: computed(() => {
          return routerParams("project_id");
        }),
      });

    const setActive = (menuItem) => {
        if (menuItem && menuItem.route) {
          // if(menuItem.name == "实体监控"){
          //   group_uuid.value = "68d3d452"
          //   const route = {
          //     path: '/'+event.project_id+'/panel-group/'+group_uuid.value,
          //   };
          //   router.push(route);

          // }else 
          // if(menuItem.name == "报警管理"){
          //   group_uuid.value = "db04ad2d"
          //   const route = {
          //     path: '/'+event.project_id+'/panel-group/'+group_uuid.value,
          //   };
          //   router.push(route);
          // }
          // }else if(menuItem.name == "KPI分析"){
          //   group_uuid.value = "d1eccb40"
          //   const route = {
          //     path: '/'+event.project_id+'/panel-group/'+group_uuid.value,
          //   };
          //   router.push(route);
          // }
          // else{
            console.log("功能菜单跳转===",menuItem,menuItem.name, menuItem.route)
            router.push(menuItem.route);
          // }
        }else{
           console.log("event",event.project_id,menuItem)
           // 构造带参数的路由
           const route = {
             path: '/'+event.project_id+'/panel-group/'+menuItem.group_uuid,
           };
           console.log("功能菜单跳转", route);
           router.push(route);
        }

    };


    return {
        setActive,
        activeIndex,
        currentRouteName,
        TopMenusJson
    }
}
