<template>
  <b-row>
    <b-col cols="10">
      <!-- <b-nav pills class="mb-2">
        <b-nav-item >统计概览</b-nav-item>
        <b-nav-item>故障列表</b-nav-item>
        <b-nav-item>通用诊断</b-nav-item>
        <b-nav-item>专业诊断</b-nav-item>
        <b-nav-item>智能诊断</b-nav-item>
        <b-nav-item>关系图谱</b-nav-item>
        <b-nav-item>实体监控</b-nav-item>
        <b-nav-item-dropdown text="KPI分析" active>
          <b-dropdown-item>KPI分析1</b-dropdown-item>
          <b-dropdown-item>KPI分析2</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item disabled>管理设定</b-nav-item>
      </b-nav> -->

      <b-nav pills class="mb-2">
        <template v-for="(item, index) in TopMenusJson">
          <template v-if="item.children">
            <b-nav-item-dropdown
              :text="item.name"
              :key="index"
              :active="index === activeIndex"
              :disabled="item.disabled"
            >
              <b-dropdown-item>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="toShowModel"
                >
                  新增页面
                </b-button>
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                v-for="(child, childIndex) in kpiList"
                :key="childIndex"
                @click.native="setActive(child)"
              >
                {{ child.group_name.replace("-kpi", "") }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </template>
          <template v-else>
            <b-nav-item
              :key="index"
              :active="item.route.name === currentRouteName"
              @click="setActive(item)"
              :disabled="item.disabled"
            >
              {{ item.name }}
            </b-nav-item>
          </template> 
        </template>
      </b-nav>
    </b-col>
    <diagnosis-dialog></diagnosis-dialog>

    <b-modal id="modal-center" v-model="formModal" centered title="新增 PanelGroup" hide-footer>
      <b-form>
        <b-form-group label="名称">
          <b-form-input
            v-model="panelGroup.name"
            placeholder="输入 PanelGroup 名称"
            required
          ></b-form-input>
        </b-form-group>
        <!-- <p>
          PanelGroup UID:
          {{ panelGroup.uid || "---" }}
        </p> -->
        <b-button
          class="my-2 float-right"
          variant="primary"
          @click="panelGroupSubmit"
          >提交
        </b-button>
      </b-form>
    </b-modal>
  </b-row>

  <!-- <b-card no-body>
        <b-tabs card>
            <b-tab title="一键诊断" active>
                <b-card-text class="text-center">
                    <p>一键诊断文字介绍</p>

                    <b-button variant="primary">
                        <b-icon-hand-index-fill
                            class="mr-50"
                        ></b-icon-hand-index-fill>
                        一键诊断</b-button
                    >

                    <b-row class="mt-2">
                        <b-col cols="4" offset="2">
                            <b-avatar variant="light-primary" size="66">
                                <b-icon-search scale="2.5"></b-icon-search
                            ></b-avatar>
                            <b-progress
                                value="60"
                                max="100"
                                show-progress
                                animated
                                class="mt-2"
                            ></b-progress>
                            <p class="mt-2">奋力诊断中...</p>
                            <b-button variant="primary">
                                <b-icon-check-circle-fill
                                    class="mr-50"
                                ></b-icon-check-circle-fill>
                                查看诊断结果</b-button
                            >
                        </b-col>
                        <b-col cols="4"
                            ><b-card
                                border-variant="primary"
                                style="height: 15rem"
                            >
                                代码可视化
                            </b-card></b-col
                        >
                    </b-row>
                </b-card-text>
            </b-tab>

            <b-tab title="故障列表">
                <b-card-text class="text-center">故障列表文字介绍</b-card-text>
                <b-row>
                    <b-col cols="2" offset="2">
                        <label>筛选1</label>
                        <b-select></b-select>
                    </b-col>
                    <b-col cols="2">
                        <label>筛选2</label>
                        <b-select></b-select>
                    </b-col>
                    <b-col cols="2">
                        <label>筛选3</label>
                        <b-select></b-select>
                    </b-col>
                    <b-col cols="2">
                        <label>筛选4</label>
                        <b-select></b-select>
                    </b-col>
                </b-row>
            </b-tab>

            <b-tab title="通用诊断">
                <b-card-text class="text-center"
                    ><p>通用诊断文字介绍</p>

                    <b-button variant="primary">
                        <b-icon-zoom-in class="mr-50"></b-icon-zoom-in>
                        通用诊断</b-button
                    ></b-card-text
                >
            </b-tab>
            <b-tab title="专业诊断">
                <b-card-text class="text-center"><p>专业诊断文字介绍</p>

                    <b-button variant="primary">
                        <b-icon-zoom-in class="mr-50"></b-icon-zoom-in>
                        专业诊断</b-button
                    ></b-card-text>
            </b-tab>
            <b-tab title="智能诊断">
                <b-card-text class="text-center"><p>智能诊断文字介绍</p>

                    <b-button variant="primary">
                        <b-icon-zoom-in class="mr-50"></b-icon-zoom-in>
                        智能诊断</b-button
                    ></b-card-text>
            </b-tab>
            <b-tab title="关系图谱">
                <b-card-text class="text-center">Instruction</b-card-text>
            </b-tab>
            <b-tab title="实体监测">
                <b-card-text class="text-center">Instruction</b-card-text>
            </b-tab>
            <b-tab title="管理设定" disabled>
                <b-card-text class="text-center">Instruction</b-card-text>
            </b-tab>
        </b-tabs>
    </b-card> -->

  <!-- <div>
        <b-button
            v-for="(item, index) in buttonFun"
            :key="index"
            :variant="item.variant"
            class="mr-1"
        >
            <feather-icon :icon="item.icon" class="mr-50" />
            {{ item.name }}
        </b-button>
        <div class="float-right">
            <span v-for="(char, index) in currentTime" :key="index">{{
                char
            }}</span>
        </div>
    </div> -->
</template>
<script>
import { onMounted, ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import useTopMenusHooks from "./hooks/TopMenusHooks";
import DiagnosisDialog from "./DiagnosisDialog.vue";
import store from "@/store";
import { showToast } from "@/libs/utils/showToast";

export default {
  name: "FunctionEntry",
  directives: {
    Ripple,
  },
  components: {
    "diagnosis-dialog": DiagnosisDialog,
  },
  setup() {
    const { setActive, activeIndex, currentRouteName, TopMenusJson } =
      useTopMenusHooks();

    //提交PanelGroup
    const panelGroup = ref({
      name: "",
      uid: "",
    });

    const formModal = ref(false)

    const kpiList = ref({});

    const panelGroupSubmit = () => {
      if (panelGroup.name != "") {
        store
          .dispatch("panel-group/addPanelGroup", {
            group_name: panelGroup.value.name + "-kpi",
          })
          .then((response) => {
            console.log("添加组件", response.data);
            panelGroup.value.uid = response.data.group_uuid;
            getKpiList();
            formModal.value = false;
            showToast("添加成功");
          });
      }
    };

    // 获取自定义组件列表
    const getKpiList = () => {
      store
        .dispatch("panel-group/fetchPanelGroupList")
        .then((response) => {
          console.log("自定义组件", response.data.list);
          const totalList = response.data.list;

          // 使用 filter 方法筛选出 group_name 包含 "-kpi" 的数据
          kpiList.value = totalList.filter((item) => {
            // 添加检查确保 item.group_name 是一个字符串并且不为 null 或 undefined
            if (
              typeof item.group_name === "string" &&
              item.group_name !== null &&
              item.group_name !== undefined
            ) {
              return item.group_name.includes("-kpi");
            }
            return false;
          });

          // 现在 filteredList 中包含了 group_name 包含 "-kpi" 的数据
          console.log('包含 "-kpi" 的数据:', kpiList.value);
        })
        .catch((error) => {
          console.error("获取数据出错：", error);
        });
    };

    const toShowModel = ()=>{
      formModal.value = true
    }

    onMounted(() => {
      getKpiList();
    });

    return {
      TopMenusJson,
      activeIndex,
      setActive,
      currentRouteName,
      panelGroup,
      panelGroupSubmit,
      kpiList,
      toShowModel,
      formModal
    };
  },
  mounted() {
    this.currentRouteName = this.$route.name;
  },
};
</script>
<style lang="scss" scoped></style>
